import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { GoalIcon, QualityIcon, MotivationIcon, DedicationIcon } from '../components/Icons';

const query = graphql`
query AboutEN {
    datoCmsAboutUsPage(locale: {eq: "en"}) {
        seo {
            title
            description
            twitterCard
            image {
                url
            }
          }
      heading
      description
      mainImage {
        gatsbyImageData
        alt
      }
      valuesHeading
      valuesText
      buttonCta
      callToAction
      valuesBox {
        icon
        heading
        description
      }
      bigImage {
        gatsbyImageData(width: 1120, height: 400, layout: FIXED)
        alt
      }
      rightImage {
        gatsbyImageData(width: 535, height: 335, layout: FIXED)
        alt
      }
      leftImage {
        gatsbyImageData(width: 535, height: 335, layout: FIXED)
        alt
      }
    }
  }
`;

const About = props => {

    const lang = props.pageContext.langKey;

    const data = useStaticQuery(query);
    const { seo, heading, description, mainImage, valuesHeading, valuesText, valuesBox, bigImage, leftImage, rightImage, buttonCta,
    callToAction } = data.datoCmsAboutUsPage;

    const getIcon = icon => {
        switch(icon){
            case 'GoalIcon':
                return <GoalIcon />
            case 'DedicationIcon':
                return <DedicationIcon />
            case 'MotivationIcon':
                return <MotivationIcon />
            case 'QualityIcon':
                return <QualityIcon />
            default:
                return null;
        }
    }

    return (
        <Layout lang={lang} location={props.location}  buttonCta={buttonCta} callToAction={callToAction}>
            <Seo
                title={ seo.title ?? '' }
                description={ seo.description ?? '' }
                meta={ seo.description ?? '' }
                image={seo && seo.image && seo.image.url}
                slug='about'
            />
            <div className="about">
                <div className="about__main">
                    <div className="container">
                        <h1 className="about__main--heading" dangerouslySetInnerHTML={{ __html: heading }} />
                        <p className="text" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                    <div className="about__main--image">
                        <GatsbyImage
                            image={mainImage.gatsbyImageData}
                            alt={ mainImage.alt }
                            title={ mainImage.alt } />
                    </div>
                </div>
                <div className="about__values background__grey">
                    <div className="container">
                        <h2 className="about__values--heading" dangerouslySetInnerHTML={{ __html: valuesHeading }} />
                        <p className="text" dangerouslySetInnerHTML={{ __html: valuesText }} />
                        <div className="about__values--container">
                            {valuesBox.map((box, index) => {
                                const { icon, heading, description } = box;
                                return (
                                    <div className="about__values--box" key={index}>
                                        <div className="about__values--icon">
                                            {getIcon(icon)}
                                        </div>
                                        <h4 className="about__values--subheading">
                                            {heading}
                                        </h4>
                                        <p className='text'>
                                            {description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="about__images">
                    <div className="container">
                        <div className="about__images--main">
                            <GatsbyImage
                                image={bigImage.gatsbyImageData}
                                alt={ bigImage.alt }
                                title={ bigImage.alt } />
                        </div>
                        <div className="about__images--container">
                            <div className="about__images--half">
                                <GatsbyImage
                                    image={leftImage.gatsbyImageData}
                                    alt={ leftImage.alt }
                                    title={ leftImage.alt } />
                            </div>
                            <div className="about__images--half">
                                <GatsbyImage
                                    image={rightImage.gatsbyImageData}
                                    alt={ rightImage.alt }
                                    title={ rightImage.alt } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default About;